import React from "react"

import PageLayout from "../../components/PageLayout/PageLayout"
import { MenuItems } from "../../components/NavMenu/Menu"
import SEO from "../../components/SEO/SEO"
import Projects from "../../components/Projects/Projects"

const IndexPage = () => (
  <PageLayout selectedMenu={MenuItems.PROJECTS}>
    <SEO
      title="Debojit's Projects"
      description="Collection of my works and sample projects"
      slug="projects/"
    />
    <Projects />
  </PageLayout>
)

export default IndexPage
